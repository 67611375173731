import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import { useEffect, useState } from 'react';

import { ModuleIconWithContent } from '@components/common/types/ModuleIconWithContent';
import { Renderer } from '@components/screen/factory';
import Button, { ButtonVariant } from '@components/ui/Button/Button';
import Container from '@components/ui/Container';
import ImageWithContent from '@components/ui/ImageWithContent';
import Text from '@components/ui/Text/Text';
import { getContentfulImgUrl, renderImage } from '@lib/image';
import { CamelCase } from '@lib/types/camel-case';
import { FCWithChildren } from '@lib/types/react-utilities';

import { ImageAlignType } from '../enums/ImageAlignType';
import { ModuleListSection } from '../types/ModuleListSection';

import style from './ListSection.module.scss';

interface Props extends ModuleListSection {
  contentClassName?: string;
  imageClassName?: string;
  className?: string;
  onClick?: () => void;
}

type ContentProps = Omit<Props, 'image' | 'mobileImage' | 'imagePosition'>;

const mobileImagePosition: Record<string, ImageAlignType> = {
  [ImageAlignType.LEFT]: ImageAlignType.TOP,
  [ImageAlignType.RIGHT]: ImageAlignType.TOP,
};

const defaultImagePos = ImageAlignType.LEFT;

const renderListItem = ({ content }: Entry<ModuleIconWithContent>) => {
  const {
    icon: { url, alt },
    headline,
    paragraph,
  } = content;

  const iconUrl = getContentfulImgUrl(url, 50);

  return (
    <div className={cn('flex flex-col w-full items-center mb-8 mt-3')}>
      <div className="w-full flex flex-row mb-2">
        {renderImage({ url: iconUrl, alt }, { className: 'h-6 object-contain mr-2' })}
        <Text variant="heading-3" className="">
          {headline}
        </Text>
      </div>
      <div className="w-full">
        <Text variant="text-2" html={paragraph} />
      </div>
    </div>
  );
};

const Content: FCWithChildren<ContentProps> = ({
  title,
  mobileTitle = title,
  description,
  listItem,
  buttonCta,
  buttonCtaUrl,
  buttonCtaVariant,
  onClick,
  children,
}) => {
  const ctaButtonVariant = camelCase(buttonCtaVariant) as CamelCase<ButtonVariant>;

  return (
    <>
      {title && (
        <Text variant="heading-2" className="mb-xs w-full hidden md:block">
          {title}
        </Text>
      )}
      {mobileTitle && (
        <Text variant="heading-2" className="mb-xs w-full block md:hidden">
          {mobileTitle}
        </Text>
      )}
      {description && <Text variant="text-2" html={description} className={style.paragraph} />}
      {listItem && listItem.length > 0 && <div>{listItem.map(renderListItem)}</div>}
      {buttonCtaUrl && buttonCta && (
        <Button
          variant={ctaButtonVariant}
          aria-label={buttonCta}
          href={buttonCtaUrl}
          className={cn(style.button)}
          onClick={onClick}
        >
          <Text variant="text-2" weight="semibold">
            {buttonCta}
          </Text>
        </Button>
      )}
      {children}
    </>
  );
};

const ListSection: FCWithChildren<Props> = ({
  image,
  mobileImage,
  imagePosition,
  lazyLoadImage = false,
  contentClassName,
  imageClassName,
  className,
  ...contentProps
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // this happens if a module is in draft mode, we just get back a sys link
  // so rather than render an empty block, return out null
  if (!contentProps?.internalName) {
    return null;
  }

  return isLoaded ? (
    <Container mobilePadding className={cn('py-8 px-6 md:px-8 lg:px-16')}>
      <div className={className}>
        {image ? (
          <ImageWithContent
            source={{
              type: image?.type || 'image',
              url: image?.url || '',
              mobileUrl: mobileImage?.url,
              alt: image?.description || image?.alt || contentProps.title,
              className: imageClassName || style.image,
              loading: lazyLoadImage ? 'lazy' : 'eager',
            }}
            className="items-center"
            type={imagePosition || defaultImagePos}
            mobileType={mobileImagePosition[imagePosition || defaultImagePos] as ImageAlignType.TOP}
            content={
              <div
                className={cn(style.wrapperWithImage, {
                  'h-full': [ImageAlignType.LEFT, ImageAlignType.RIGHT].indexOf(imagePosition as ImageAlignType) > -1,
                })}
              >
                <Content {...contentProps} />
              </div>
            }
          />
        ) : (
          <div className={cn(style.wrapper, contentClassName)}>
            <Content {...contentProps} />
          </div>
        )}
      </div>
    </Container>
  ) : null;
};

export default Renderer({ name: 'moduleListSection' })(ListSection);
