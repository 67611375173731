import { Entry } from '@services/contentful/types';
import cn from 'classnames';
import { FC } from 'react';

import { Image } from '@commerce/types/common';
import { ProductMarqueeItem } from '@components/product/types';
import { Renderer } from '@components/screen/factory';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Link from '@components/ui/Link';
import Text from '@components/ui/Text/Text';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';
import { renderImage } from '@lib/image';

import s from './ExclusiveBenefits.module.scss';

type Alignment = 'left' | 'right' | 'center';
type Theme = 'lightblue' | 'blue' | 'mintgreen' | 'purple';

interface Props {
  title: string;
  titleAlign?: Alignment;
  backgroundImage?: Image;
  backgroundImageMobile?: Image;
  icons: Entry<ProductMarqueeItem>[];
  ctaTitle: string;
  ctaUrl: string;
  ctaAlign?: Alignment;
  theme: Theme;
}

interface ContentProps extends Omit<Props, 'icons' | 'backgroundImage' | 'backgroundImageMobile'> {
  className?: string;
  backgroundImage?: {
    mobile?: Image;
    desktop?: Image;
  };
  titleAlign: Alignment;
  ctaAlign: Alignment;
}

const Content: FC<ContentProps> = ({
  className,
  theme,
  title,
  titleAlign,
  backgroundImage,
  ctaTitle,
  ctaUrl,
  ctaAlign,
}) => (
  <section className={cn('items-center min-h-[320px] relative', backgroundImage ? s.hasBgImage : s[theme], className)}>
    {backgroundImage?.mobile && backgroundImage?.desktop && (
      <>
        <ImageOrVideo
          srcSet={{
            mobile: {
              ...backgroundImage.mobile,
              type: 'image',
              className: s.contentBgImage,
            },
            desktop: {
              ...backgroundImage.desktop,
              type: 'image',
              className: s.contentBgImage,
            },
          }}
        />
        <div className={s.contentBgOverlay} />
      </>
    )}
    <div className={s.contentWrapper}>
      <Text variant="header-3" className={cn(s.title, s[titleAlign])}>
        {title}
      </Text>
      <div className={cn(s.ctaWrapper, s[ctaAlign])}>
        <Link href={ctaUrl} className={s.cta}>
          {ctaTitle}
        </Link>
      </div>
    </div>
  </section>
);

const ExclusiveBenefits: FC<Props> = (props) => {
  const { icons, backgroundImage, backgroundImageMobile, ...otherProps } = props;
  const isMobile = useMatchMedia('768px');
  const contentProps: Omit<ContentProps, 'backgroundImage'> = {
    ...otherProps,
    titleAlign: otherProps.titleAlign || 'center',
    ctaAlign: otherProps.ctaAlign || 'center',
  };

  const renderContent = () =>
    backgroundImage || backgroundImageMobile ? (
      <>
        <Content
          {...contentProps}
          backgroundImage={{ mobile: backgroundImageMobile, desktop: backgroundImage }}
          className={isMobile ? 'flex md:hidden' : 'hidden md:flex'}
        />
      </>
    ) : (
      <Content {...contentProps} className="flex" />
    );

  return (
    <div className={s.container}>
      {renderContent()}
      <section className={s.iconContainer}>
        <div className={s.iconWrapper}>
          {icons
            ?.map(({ content }) => content)
            .map(({ internalName, icon, description }) => (
              <div className={s.iconItem} key={internalName}>
                {renderImage(
                  { url: icon.url, alt: icon.alt || icon.description },
                  { objectFit: 'contain', className: s.iconImage, loading: 'lazy' }
                )}
                <Text variant="base" className="text-center text-blue-900">
                  {description}
                </Text>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default Renderer({ name: 'moduleExclusiveBenefits' })(ExclusiveBenefits);
