import { Entry } from '@services/contentful/types';
import React, { FC, useState } from 'react';

import Logo from '@components/common/Logo/Logo';
import { ModuleLogo } from '@components/common/types/ModuleLogo';
import { ContentModule } from '@components/common/types/Page';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Container from '@components/ui/Container';

import s from './FeaturedPartnerLogos.module.scss';

interface Props extends ContentModule {
  mainLogo?: ModuleLogo;
  logos: ModuleLogo[];
}

interface RendererProps extends ContentModule {
  mainLogo: Entry<ModuleLogo>;
  logos: Entry<ModuleLogo>[];
}

const FeaturedPartnerLogos: FC<Props> = ({ mainLogo, logos }) => {
  const [mainFade, setMainFaide] = useState('fadeIn');
  const [hoveredLogoIdx, setHoveredLogoIdx] = useState<number>(-1);

  return (
    <Container>
      <div className="py-2 md:py-4 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
        {mainLogo && (
          <Logo
            className="col-span-2 row-span-2"
            source={{
              type: 'image',
              url: mainLogo.image?.url || '',
              alt: mainLogo.title,
              className: `h-full object-cover ${mainFade === 'fadeIn' ? s.fadeIn : s.fadeOut}`,
            }}
            name={mainLogo.title}
            showName={mainLogo.showTitle}
            url={mainLogo.url}
            onMouseEnter={() => setMainFaide('fadeOut')}
            onMouseLeave={() => setMainFaide('fadeIn')}
          />
        )}

        {logos.map(({ internalName, image, title, showTitle, url }: ModuleLogo, idx: number) => (
          <React.Fragment key={internalName}>
            <Logo
              source={{
                type: 'image',
                url: image?.url || '',
                alt: title,
                className: `h-full object-cover ${hoveredLogoIdx === idx ? s.fadeOut : s.fadeIn}`,
              }}
              name={title}
              showName={showTitle}
              url={url}
              onMouseEnter={() => setHoveredLogoIdx(idx)}
              onMouseLeave={() => setHoveredLogoIdx(-1)}
            />
          </React.Fragment>
        ))}
      </div>
    </Container>
  );
};

const mapper = ({ mainLogo, logos, ...rest }: RendererProps): Props => ({
  ...rest,
  mainLogo: mapContent(mainLogo),
  logos: mapContent(logos),
});

export default Renderer({ name: 'moduleFeaturedPartnerLogos', mapper })(FeaturedPartnerLogos);
