import { Entry } from '@services/contentful/types';
import { FC } from 'react';

import { ModulePartner } from '@components/common/types/ModulePartner';
import { Teaser } from '@components/common/types/Teaser';
import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Container from '@components/ui/Container';

import PartnersContainer, { Props as PartnerContainerProps } from './PartnersContainer';

interface RendererProps {
  moduleTeaser: Entry<Teaser>;
  partners: Entry<ModulePartner>[];
}

const PartnersContainerRenderer: FC<PartnerContainerProps> = ({ moduleTeaser, partners }) => {
  return (
    <Container mobilePadding>
      <PartnersContainer moduleTeaser={moduleTeaser} partners={partners} className="mt-4 py-2 md:py-12 content" />
    </Container>
  );
};

const mapper = ({ partners, moduleTeaser, ...rest }: RendererProps): PartnerContainerProps => ({
  ...rest,
  moduleTeaser: mapContent(moduleTeaser),
  partners: mapContent(partners),
});

export default Renderer({ name: 'modulePartnersContainer', mapper })(PartnersContainerRenderer);
