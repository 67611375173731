import 'swiper/css/pagination';

import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'next-i18next';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import Container from '@components/ui/Container';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Text from '@components/ui/Text/Text';
import { FontSizes, FontTypes } from '@components/ui/types/ContentfulTextOption';
import { trackEvent } from '@lib/gtag';
import { useMatchMedia } from '@lib/hooks/useMatchMedia';
import EffectMaterial from '@lib/swiper/effects/effect-material.esm';
import { isVideo } from '@lib/video';

import Button from '../Button/Button';
import SwiperPagination from './SwiperPagination';

import style from './HeroCarousel.module.scss';

interface SlideContent {
  id: string;
  content: {
    internalName: string;
    customHeader?: string;
    customSubheader?: string;
    primaryCta?: string;
    primaryUrl?: string;
    secondaryCta?: string;
    secondaryUrl?: string;
    alignment?: 'Left' | 'Center' | 'Right';
    media: Image | Video;
    mobileMedia: Image | Video;
    videoThumbnail: Image;
    mobileVideoThumbnail: Image;
    isBlackText?: boolean;
    headerFontSize?: FontSizes;
    headerFontType?: FontTypes;
    primaryCtaColor: 'Purple' | 'Black' | 'White';
  };
}
interface ModuleOverview {
  interval: number;
  slides: SlideContent[];
}

const HeroCarousel: FC<ModuleOverview> = ({ slides, interval }) => {
  const sliderRef = useRef<SwiperRef | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const router = useRouter();
  const isMobile = useMatchMedia('768px');

  const { t } = useTranslation(['common']);

  const onAutoplayTimeLeft = useCallback(
    (s: any, time: any, progress: number) => {
      paginationRef.current?.style.setProperty('--progress', `${100 * (1 - progress)}%`);
    },
    [paginationRef]
  );

  const handleSlideChange = () => {
    if (sliderRef.current?.swiper.realIndex !== activeSlide) {
      const currentIndex = sliderRef.current?.swiper.realIndex;
      trackEvent({
        action: 'Hero Carousel Slide View',
        category: slides[currentIndex as number].content.internalName,
        label: slides[currentIndex as number].content.customHeader,
      });
      setActiveSlide(currentIndex as number);
    }
  };

  // this is just to log the initial slide
  useEffect(() => {
    trackEvent({
      action: 'Hero Carousel Slide View',
      category: slides[0].content.internalName,
      label: slides[0].content.customHeader,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this is to run animations loop properly if less than 5 slides are set
  if (slides.length > 1 && slides.length < 5) {
    // eslint-disable-next-line no-param-reassign
    slides = slides.concat(slides);
  }

  const navigateTo = useCallback(
    (url: string) => {
      if (isMobile) {
        router.push(url);
      }
    },
    [isMobile, router]
  );

  return slides?.length > 1 ? (
    <div className={style.carouselSection}>
      <Swiper
        ref={sliderRef}
        className={style.swiperMaterial}
        modules={[Autoplay, Pagination, EffectMaterial, Navigation]}
        effect="material"
        spaceBetween={10}
        slidesPerView={1.15}
        speed={900}
        centeredSlides
        loop
        grabCursor
        navigation={{
          prevEl: '.hero-prev',
          nextEl: '.hero-next',
        }}
        pagination={{
          el: `.${style.materialPagination}`,
          clickable: true,
          bulletActiveClass: style.materialPaginationBulletActive,
          renderBullet(index, className) {
            return `<span class="${cn(style.materialPaginationBullet, className)}"><div class="${
              style.wormLoader
            }"></div></span>`;
          },
        }}
        autoplay={{
          disableOnInteraction: false,
          delay: interval || 4000,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          1080: {
            slidesPerView: 2,
          },
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={handleSlideChange}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide
              key={`slide-${slide.id + index}`}
              onClick={() => navigateTo(slide.content.primaryUrl || slide.content.secondaryUrl || '')}
            >
              <div className={cn(style.swiperMaterialWrapper, 'swiper-material-wrapper')}>
                <div className={style.swiperMaterialContent}>
                  <ImageOrVideo
                    srcSet={{
                      mobile: {
                        className: cn(style.materialCarouselMedia, 'visible md:invisible'),
                        type: isVideo(slide.content.media.type) ? slide.content.media.type! : 'image',
                        thumbnail: slide.content.mobileVideoThumbnail?.url || undefined,
                        renderAsThumbnail: !!isVideo(slide.content.media.type),
                        playOnActive: isVideo(slide.content.media.type) && activeSlide === index,
                        ...slide.content.mobileMedia,
                      },
                      desktop: {
                        className: cn(style.materialCarouselMedia, 'hidden md:block'),
                        type: isVideo(slide.content.media.type) ? slide.content.media.type! : 'image',
                        thumbnail: slide.content.videoThumbnail?.url || undefined,
                        renderAsThumbnail: !!isVideo(slide.content.media.type),
                        playOnActive: isVideo(slide.content.media.type) && activeSlide === index,
                        ...slide.content.media,
                      },
                    }}
                    data-swiper-material-scale={slide.content.media && 1.25}
                  />
                  <div
                    className={cn(
                      style.materialCarouselCopy,
                      slide.content.alignment === 'Center' ? 'items-center text-center' : 'items-start',
                      'swiper-material-animate-opacity'
                    )}
                    style={{ color: slide.content.isBlackText ? 'var(--black)' : 'var(--white)' }}
                  >
                    {slide.content.customHeader && (
                      <Text
                        className={cn(
                          style.slideHeader,
                          style[slide.content.headerFontSize || FontSizes.L],
                          style[slide.content.headerFontType || FontTypes.Normal]
                        )}
                        html={index === 0 ? `<h1>${slide.content.customHeader}</h1>` : `${slide.content.customHeader}`}
                      />
                    )}
                    {slide.content.customSubheader && (
                      <Text className={style.subHeader} variant="base" html={slide.content.customSubheader} />
                    )}
                    <div className={style.materialCarouselCtas}>
                      {slide.content.primaryCta && (
                        <Button
                          className={cn(
                            style.primaryCta,
                            style[`primaryCta${slide.content.primaryCtaColor || 'Purple'}`]
                          )}
                          variant="cta"
                          href={slide.content.primaryUrl}
                          gtm={{
                            action: 'Hero Carousel Primary CTA',
                            category: `${slide.content.internalName} > ${slide.content.primaryCta}`,
                            label: slide.content.primaryUrl,
                          }}
                        >
                          {slide.content.primaryCta}
                        </Button>
                      )}
                      {slide.content.secondaryCta && (
                        <Button
                          className={style.secondaryCta}
                          variant="cta"
                          href={slide.content.secondaryUrl}
                          gtm={{
                            action: 'Hero Carousel Secondary CTA',
                            category: `${slide.content.internalName} > ${slide.content.secondaryCta}`,
                            label: slide.content.secondaryUrl,
                          }}
                        >
                          {slide.content.secondaryCta}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <button
        type="button"
        className={cn(style.navButtonPrev, 'hero-prev absolute left-0 cursor-pointer z-10')}
        aria-label={t('common:screenReader.previousSlide')}
      />
      <button
        type="button"
        className={cn(style.navButtonNext, 'hero-next absolute right-0 cursor-pointer z-10')}
        aria-label={t('common:screenReader.nextSlide')}
      />
      <SwiperPagination ref={paginationRef} />
    </div>
  ) : (
    // This is to cover Single slide case
    <div className={cn(style.banner, 'relative overflow-hidden')}>
      {slides[0].content.media && (
        <ImageOrVideo
          {...slides[0].content.media}
          className={cn(style.materialCarouselMedia, 'hidden sm:block')}
          type={isVideo(slides[0].content.media.type) ? slides[0].content.media.type! : 'image'}
          thumbnail={slides[0].content.videoThumbnail?.url || undefined}
          renderAsThumbnail={!!isVideo(slides[0].content.media.type)}
          playOnActive={isVideo(slides[0].content.media.type)}
        />
      )}
      {slides[0].content.mobileMedia && (
        <ImageOrVideo
          {...slides[0].content.mobileMedia}
          className={cn(style.materialCarouselMedia, 'visible sm:invisible')}
          type={isVideo(slides[0].content.media.type) ? slides[0].content.media.type! : 'image'}
          thumbnail={slides[0].content.mobileVideoThumbnail?.url || undefined}
          renderAsThumbnail={!!isVideo(slides[0].content.media.type)}
          playOnActive={isVideo(slides[0].content.media.type)}
        />
      )}
      <div
        className={style.materialCarouselCopy}
        style={{ color: slides[0].content.isBlackText ? 'var(--black)' : 'var(--white)' }}
      >
        <Container
          className={cn(
            slides[0].content.alignment === 'Center' ? 'items-center text-center' : 'items-start',
            'flex flex-col'
          )}
        >
          {slides[0].content.customHeader && (
            <Text
              className={cn(
                style.slideHeader,
                style[slides[0].content.headerFontSize || FontSizes.L],
                style[slides[0].content.headerFontType || FontTypes.Normal]
              )}
              html={`<h1>${slides[0].content.customHeader}</h1>`}
            />
          )}
          {slides[0].content.customSubheader && (
            <Text className={style.subHeader} variant="base" html={slides[0].content.customSubheader} />
          )}
          <div className={style.materialCarouselCtas}>
            {slides[0].content.primaryCta && (
              <Button
                className={cn(style.primaryCta, style[`primaryCta${slides[0].content.primaryCtaColor || 'Purple'}`])}
                variant="cta"
                href={slides[0].content.primaryUrl}
                gtm={{
                  action: 'Hero Carousel Primary CTA',
                  category: `${slides[0].content.internalName} > ${slides[0].content.primaryCta}`,
                  label: slides[0].content.primaryUrl,
                }}
              >
                {slides[0].content.primaryCta}
              </Button>
            )}
            {slides[0].content.secondaryCta && (
              <Button
                className={style.secondaryCta}
                variant="cta"
                href={slides[0].content.secondaryUrl}
                gtm={{
                  action: 'Hero Carousel Secondary CTA',
                  category: `${slides[0].content.internalName} > ${slides[0].content.secondaryCta}`,
                  label: slides[0].content.secondaryUrl,
                }}
              >
                {slides[0].content.secondaryCta}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Renderer({ name: 'moduleHeroCarousel' })(HeroCarousel);
