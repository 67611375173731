import { FC, useMemo } from 'react';

import CrossSellProducts from '@components/product/ProductView/CrossSellProducts/CrossSellProducts';
import { Renderer } from '@components/screen/factory';
import Container from '@components/ui/Container';
import { useMobileViewport } from '@lib/hooks/useMobileViewport';

import TurnToCommentDisplay from '../TurnToCommentDisplay/TurnToCommentDisplay';
import { GenericContainerProps } from '../types/GenericContainer';

const GenericContainer: FC<GenericContainerProps> = ({ moduleType, header, crossSellProductsContent, sku }) => {
  const isMobile = useMobileViewport();

  const { show: showCrossSell, loading: crossSellLoading, data: crossSellProducts } = crossSellProductsContent || {};

  const crossSellProductsWithoutOOS = useMemo(
    () => crossSellProducts?.filter((p) => !!p?.inventory?.isInStock),
    [crossSellProducts]
  );

  const renderContainer = () => {
    switch (moduleType) {
      case 'crossSellProducts':
        return (
          showCrossSell && (
            <CrossSellProducts
              products={crossSellProductsWithoutOOS}
              loading={crossSellLoading}
              isMobileView={isMobile}
              title={header}
            />
          )
        );
      case 'turnToCommentsDisplay':
        return <TurnToCommentDisplay header={header} sku={sku} />;
      default:
        return <></>;
    }
  };

  return <Container className="mb-6 md:mb-12 flex flex-col gap-y-6 md:gap-y-12">{renderContainer()}</Container>;
};

export default Renderer({ name: 'moduleGenericContainer' })(GenericContainer);
