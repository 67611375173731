import 'swiper/css/pagination';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useRef } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ModulePillBoxes, STYLE_VARIANT } from '@components/common/types/ModulePillBoxes';
import { Renderer } from '@components/screen/factory';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';

import PillBoxItem from './PillBoxItem/PillBoxItem';

import s from './PillBoxes.module.scss';

const PillBoxes: FC<ModulePillBoxes> = (props) => {
  const { t } = useTranslation(['common']);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const { eyebrow, header, pills, styleVariant } = props;
  const isSlider = styleVariant === STYLE_VARIANT.SLIDER;

  const component = (
    <>
      <Text color="var(--eggplant)" variant="heading-3" className={s.eyebrow} asElement="p">
        {eyebrow}
      </Text>
      <Text variant="heading-2" className={cn(s.header, { [s.headerSlider]: isSlider })} asElement="h2">
        {header}
      </Text>
      <div className={s.pillsContainer}>
        {isSlider ? (
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            spaceBetween={16}
            loop
            navigation={{
              prevEl: prevRef.current ? prevRef.current : undefined, // Assert non-null
              nextEl: nextRef.current ? nextRef.current : undefined, // Assert non-null
            }}
            onInit={(swiper) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = prevRef.current;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            pagination={{
              clickable: true,
              bulletClass: s.swiperPaginationBullet,
              bulletActiveClass: s.activeSwiperPaginationBullet,
              el: `.${s.swiperPagination}`,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 16,
                centeredSlides: false,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 24,
                centeredSlides: false,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 32,
                centeredSlides: false,
              },
              1600: {
                slidesPerView: 3,
                spaceBetween: 48,
                centeredSlides: false,
              },
            }}
          >
            {pills.map((pill, index) => (
              <SwiperSlide key={`pill-${pill.content.internalName}`}>
                <PillBoxItem {...pill.content} styleVariant={styleVariant} index={index + 1} />
              </SwiperSlide>
            ))}
            <div className={s.arrowContainer}>
              <button
                type="button"
                ref={prevRef}
                className={s.arrow}
                aria-label={t('common:screenReader.previousSlide')}
                title={t('common:screenReader.previousSlide')}
              >
                <FontAwesomeIcon icon={faChevronLeft} size="2x" title={t('common:screenReader.previousSlide')} />
              </button>
              <button
                type="button"
                ref={nextRef}
                className={s.arrow}
                aria-label={t('common:screenReader.nextSlide')}
                title={t('common:screenReader.nextSlide')}
              >
                <FontAwesomeIcon icon={faChevronRight} size="2x" title={t('common:screenReader.nextSlide')} />
              </button>
            </div>
            <div className={cn(s.swiperPagination, 'featured-slide-pagination')} />
          </Swiper>
        ) : (
          pills.map((pill, index) => (
            <PillBoxItem
              key={`pill-${pill.content.internalName}`}
              {...pill.content}
              styleVariant={styleVariant}
              index={index + 1}
            />
          ))
        )}
      </div>
    </>
  );

  return (
    <div className={cn(s.container, { [s.containerSlider]: isSlider })}>
      {isSlider ? (
        // slider should go through the border of the screen
        component
      ) : (
        <Container>
          <div className="md:pb-8 md:pt-4 md:px-8 px-16 pt-4">{component}</div>
        </Container>
      )}
    </div>
  );
};

export default Renderer({ name: 'modulePillBoxes' })(PillBoxes);
