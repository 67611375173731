import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Linkable } from '@components/common/Linkable/Linkable';
import { ModuleImageWindscreen } from '@components/common/types/ModuleImageWindscreen';
import { Renderer } from '@components/screen/factory';
import Link from '@components/ui/Link';

import ImageOrVideo from '../ImageOrVideo/ImageOrVideo';

import style from './ImageWindscreen.module.scss';

type Props = ModuleImageWindscreen;

enum ImageSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

const getClassName = (size: ImageSize = ImageSize.DESKTOP): string => {
  switch (size) {
    case ImageSize.MOBILE:
      return style.mobileImage;
    case ImageSize.TABLET:
      return style.tabletImage;
    default:
      return style.image;
  }
};

const ImageWindscreen: FC<Props> = ({ ctaUrl, ctaText, image, tabletImage, mobileImage }) => {
  const { t } = useTranslation(['common']);
  const content = (
    <>
      <section className="w-full h-full overflow-hidden">
        <ImageOrVideo type="image" {...image} enableGifControl={!ctaUrl} className={getClassName()} />
        <ImageOrVideo
          type="image"
          {...(mobileImage || image)}
          enableGifControl={!ctaUrl}
          className={getClassName(ImageSize.MOBILE)}
        />
        <ImageOrVideo
          type="image"
          {...(tabletImage || image)}
          enableGifControl={!ctaUrl}
          className={getClassName(ImageSize.TABLET)}
        />
      </section>
      {ctaUrl && (
        <Link href={ctaUrl}>
          <span className="sr-only">{ctaText || t('common:screenReader.visit', { url: ctaUrl })}</span>
        </Link>
      )}
    </>
  );

  return image.url.includes('.gif') || ctaUrl ? (
    <Linkable className={cn(style.root)}>{content}</Linkable>
  ) : (
    <div className={cn(style.root)}>{content}</div>
  );
};

export default Renderer({ name: 'moduleImageWindscreen' })(ImageWindscreen);
