import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';

import FiftyFiftyColumnsTextAndImage from '../FiftyFiftyColumnsTextAndImage/FiftyFiftyColumnsTextAndImage';
import { ModuleTeaserGroup, ModuleTeaserGroupContent } from '../types/ModuleTeaserGroup';

import s from './TeaserGroup.module.scss';

interface Props extends ModuleTeaserGroup {
  className?: string;
  renderHeadline?: (headline: string | null) => JSX.Element;
}

interface RendererProps extends ModuleTeaserGroupContent {
  className?: string;
  renderHeadline?: (headline: string | null) => JSX.Element;
}

const TeaserGroup: FC<Props> = ({ items = [], headline, className, renderHeadline }) => (
  <Container mobilePadding>
    <div className={className}>
      {headline &&
        (renderHeadline ? (
          renderHeadline(headline)
        ) : (
          <div className="max-w-4xl mx-auto mb-5 mt-8">
            <Text variant="heading-2" className={cn(s.sectionTitle, 'hidden md:block')}>
              {headline}
            </Text>
            <Text variant="heading-1" className={cn(s.sectionTitle, 'block md:hidden')} asElement="h2">
              {headline}
            </Text>
          </div>
        ))}
      {items.map((item, idx) => (
        <div className={cn('max-w-4xl mx-auto', idx !== 0 && 'mt-8')} key={item.title}>
          <FiftyFiftyColumnsTextAndImage {...item} />
        </div>
      ))}
    </div>
  </Container>
);

const mapper = ({ items, ...rest }: RendererProps): Props => ({ ...rest, items: mapContent(items) });

export default Renderer({ name: 'moduleTeaserGroup', mapper })(TeaserGroup);
