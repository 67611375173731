import { useRouter } from 'next/router';
import { FC } from 'react';

import { Product } from '@commerce/types/product';
import ProductReviews from '@components/product/ProductView/ProductReviews';
import { Renderer } from '@components/screen/factory';
import AccordionUI from '@components/ui/Accordion/Accordion';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';

import { ModuleAccordion, ModuleAccordionTab } from '../../common/types/ModuleAccordion';

import s from './Accordion.module.scss';

interface Props extends ModuleAccordion {
  product: Product;
  reviewTabCollapsedState?: boolean;
  setReviewTabCollapsed?: (collapsed: boolean) => void;
}

const Accordion: FC<Props> = (props) => {
  const { asPath } = useRouter();
  const { eyebrow, tabs, product, reviewTabCollapsedState, setReviewTabCollapsed } = props;
  const items = tabs.map((tab) => tab.content);

  const renderContent = (element: ModuleAccordionTab) => {
    switch (element.placeholderFor) {
      case 'turnToReview':
        return <ProductReviews sku={product.sku} pathname={asPath} />;
      default:
        return <Text variant="text-2" html={element.content} className={s.content} />;
    }
  };

  return (
    <Container mobilePadding className="my-12 mx-auto">
      <Text color="var(--purple)" variant="heading-3" className={s.eyebrow} asElement="p">
        {eyebrow}
      </Text>
      {items.map((element: ModuleAccordionTab) => (
        <div key={element.tabName} className="w-full">
          <AccordionUI
            className={s.title}
            header={<Text variant="heading-3">{element.tabName}</Text>}
            separator
            collapsed={element.placeholderFor === 'turnToReview' ? reviewTabCollapsedState : true}
            setCollapsed={element.placeholderFor === 'turnToReview' ? setReviewTabCollapsed : undefined}
          >
            {renderContent(element)}
          </AccordionUI>
        </div>
      ))}
    </Container>
  );
};

export default Renderer({ name: 'moduleAccordion' })(Accordion);
