import { FC, useEffect } from 'react';

import useTurnTo from '@lib/hooks/useTurnTo';

interface TurnToCommentDisplayProps {
  header?: string;
  sku: string;
}
const TurnToCommentDisplay: FC<TurnToCommentDisplayProps> = ({ header, sku }) => {
  const { init } = useTurnTo({
    pageId: 'pdp-page',
    sku,
  });

  useEffect(() => {
    if (init && (window as any).TurnToCmd) {
      (window as any).TurnToCmd('set', { sku });
    }
  }, [init, sku]);

  return (
    <div>
      <div id="tt-chatter-widget" title={header} />
    </div>
  );
};

export default TurnToCommentDisplay;
