import { FC, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { TURN_TO_SUMMARY_ELEMENT_ID } from '@components/product/constants';
import useTurnTo from '@lib/hooks/useTurnTo';

interface Props {
  sku?: string;
  pathname?: string;
}

const ReviewWidget: FC<Props> = ({ sku, pathname }) => {
  const [isInit, setIsInit] = useState(false);

  useTurnTo(
    {
      pageId: 'pdp-page',
      schemaOrgEntityType: 'TurnTo',
      sku,
    },
    pathname
  );

  const startWidgets = () => {
    if ((window as any).TurnToCmd) {
      (window as any).TurnToCmd('initialize', 'reviews-summary');
      (window as any).TurnToCmd('initialize', 'reviews-list');
      setIsInit(true);
    }
  };

  return (
    <InView onChange={startWidgets} skip={isInit}>
      <section className="px-5 md:px-0">
        <div id={TURN_TO_SUMMARY_ELEMENT_ID} />
        <div id="tt-reviews-list" />
      </section>
    </InView>
  );
};

export default ReviewWidget;
