import { useRouter } from 'next/router';
import { FC } from 'react';

import { SubscriptionTier } from '@components/product/enums';
import { SubscriptionLogo } from '@components/product/helpers';
import Button from '@components/ui/Button/Button';
import Text from '@components/ui/Text/Text';

import s from './PremiumPlanItem.module.scss';

interface Props {
  tier: SubscriptionTier;
  headLine: string;
  title: string;
  paragraph?: string;
  ctaUrl?: string;
  ctaText?: string;
}

const PremiumPlanItem: FC<Props> = (props) => {
  const { tier, headLine, title, paragraph, ctaUrl, ctaText } = props;
  const router = useRouter();

  return (
    <div className="bg-light-gray px-8 py-10 flex flex-col justify-between h-full">
      <div>
        <SubscriptionLogo className={s.premiumLogo} tier={tier} />
        <Text variant="heading-3" className={s.headLine} asElement="p">
          {headLine}
        </Text>
        <Text variant="heading-2" className={s.title} asElement="h3">
          {title}
        </Text>
        <Text variant="text-2" html={paragraph} color="var(--secondary-2)" className="mb-2" />
      </div>
      <div>
        {ctaText && (
          <Button
            variant="cta"
            href={ctaUrl}
            aria-label={ctaText}
            gtm={{ action: 'Premium Plan CTA', category: router.pathname, label: ctaText }}
          >
            <Text variant="heading-3" asElement="span">
              {ctaText}
            </Text>
          </Button>
        )}
      </div>
    </div>
  );
};

export default PremiumPlanItem;
