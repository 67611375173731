import { Teaser } from '@components/common/types/Teaser';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';

import s from './PartnersInfo.module.scss';

const PartnersInfo = ({ paragraph }: Teaser) => (
  <Container>
    <div className="py-4 md:py-7 content">
      <Text html={paragraph} className={s.description} />
    </div>
  </Container>
);

export default PartnersInfo;
