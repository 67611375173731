import cn from 'classnames';
import { FC } from 'react';

import { ModuleIconWithContent } from '@components/common/types/ModuleIconWithContent';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';

interface Props extends ModuleIconWithContent {
  className?: string;
}

const IconWithContent: FC<Props> = ({ icon: { url, alt }, headline, paragraph, className }) => (
  <div className={cn('flex w-full items-center my-12', className)}>
    <div className="w-2/12 lg:block hidden">{renderImage({ url, alt }, { className: 'm-auto' })}</div>
    <div className="lg:w-10/12 w-full lg:ml-16 mx-4">
      <Text variant="heading-3" className="mb-xs">
        {headline}
      </Text>
      <Text variant="text-2" html={paragraph} />
    </div>
  </div>
);

export default IconWithContent;
