import cn from 'classnames';
import { FC } from 'react';

import { ImageAlignType } from '@components/common/enums/ImageAlignType';
import { ModuleWindscreen } from '@components/common/types/ModuleWindscreen';
import { Renderer } from '@components/screen/factory';
import { FontSizes, FontTypes } from '@components/ui/types/ContentfulTextOption';
import sanitizeString from '@lib/sanitize-string';

import Button from '../Button/Button';
import ImageOrVideo from '../ImageOrVideo/ImageOrVideo';

import style from './Windscreen.module.scss';

type Props = ModuleWindscreen;

const Windscreen: FC<Props> = ({
  headline,
  description,
  ctaText,
  ctaUrl,
  theme,
  image,
  mobileImage,
  imagePosition,
  titleFontSize = FontSizes.L,
  titleFontType = FontTypes.Normal,
}) => {
  return (
    <div className={style[theme ? sanitizeString(theme) : 'default']}>
      <div
        className={cn(style.root, {
          [style.imageLeft]: imagePosition === ImageAlignType.LEFT,
          [style.imageRight]: imagePosition === ImageAlignType.RIGHT,
        })}
      >
        <section
          className={cn(style.contentRoot, {
            [style.right]: imagePosition === ImageAlignType.LEFT,
            [style.left]: imagePosition === ImageAlignType.RIGHT,
          })}
        >
          <div className={style.content}>
            {headline && <h1 className={cn(style.headline, style[titleFontSize], style[titleFontType])}>{headline}</h1>}
            <div>
              {description && <p className={style.description}>{description}</p>}
              {ctaText && ctaUrl && (
                <Button variant="cta" href={ctaUrl} className={style.cta}>
                  {ctaText}
                </Button>
              )}
            </div>
          </div>
        </section>
        <section className="w-full md:w-1/2 h-full overflow-hidden">
          <ImageOrVideo
            className={style.image}
            type="image"
            url={image.url}
            alt={image.alt}
            mobileUrl={mobileImage?.url}
            mobileAlt={mobileImage?.alt}
            priority
          />
        </section>
      </div>
    </div>
  );
};

export default Renderer({ name: 'moduleWindscreen' })(Windscreen);
