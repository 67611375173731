/*
    COMPONENT MODEL: https://app.contentful.com/spaces/nywvtjnh7p05/environments/staging/content_types/moduleTabContent/fields
    TABS MODEL: https://app.contentful.com/spaces/nywvtjnh7p05/environments/staging/content_types/moduleContentTabsTab/fields
*/
import cn from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import Container from '@components/ui/Container';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Text from '@components/ui/Text/Text';
import { renderImage } from '@lib/image';
import { isVideo } from '@lib/video';

import style from './ContentTabs.module.scss';

// Type definitions
interface TabContent {
  name: string;
  mobileName: string;
  icon: Image;
  headline: string;
  body: string;
  media: Image | Video;
  mobileMedia: Image | Video;
  videoThumbnail: Image;
}

interface Tab {
  content: TabContent;
}

interface TabProps {
  eyebrow?: string;
  tabs: Tab[];
}
const SOS_ICON = '/svgs/tabs/default_icon.svg';

const Icon = ({ icon }: { icon: Image }) => {
  return renderImage(
    { url: icon?.url || SOS_ICON, alt: icon?.alt || 'SOS Icon' },
    { 'aria-hidden': true, loading: 'lazy', className: style.icon }
  );
};

const ContentTabs: FC<TabProps> = ({ eyebrow, tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tabContentRefs = useRef<HTMLDivElement[]>([]);

  // Use a callback to set the tab content references
  const setTabContentRef = useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      if (el) {
        tabContentRefs.current[index] = el;
      }
    },
    []
  );

  // Set the height of the container to the active tab's to compensate for the transition effect needing pos absolute for tab content
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const activeContent = tabContentRefs.current[activeTab];
      if (activeContent) {
        container.style.height = `${activeContent.scrollHeight}px`;
      }
    }
  }, [tabs, activeTab]);

  return (
    <Container>
      {eyebrow && (
        <Text color="var(--black)" className="text-center uppercase my-8" variant="base-bold" asElement="h2">
          {eyebrow}
        </Text>
      )}
      <div className="p-4">
        <div
          className={cn(
            style.tabsHeader,
            'flex flex-wrap justify-center xs:flex-row xs:gap-2.5 md:gap-8 lg:flex-nowrap lg:gap-3 flex space-x-2 mb-4'
          )}
        >
          {tabs.map((tab, index) => {
            const { name, icon, mobileName } = tab.content;

            return (
              <div key={name} className={style.tabContainer}>
                <button
                  type="button"
                  className={cn(style.tab, { [style.active]: index === activeTab })}
                  onClick={() => setActiveTab(index)}
                >
                  <div className="flex flex-col items-center">
                    <Icon icon={icon} />
                    <Text className="mt-1 block md:hidden" color="var(--black)" variant="xsmall" asElement="p">
                      {mobileName}
                    </Text>
                    <Text className="mt-1 hidden md:block" color="var(--black)" variant="base" asElement="p">
                      {name}
                    </Text>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
        <div ref={containerRef} className={style.tabAnimationContainer}>
          {tabs.map((tab, index) => {
            const { headline, body, media, mobileMedia, videoThumbnail, name } = tab.content;

            return (
              <div
                key={`${name}-content`}
                ref={setTabContentRef(index)}
                className={cn(style.tabContentContainer, 'absolute w-full', {
                  [style.active]: index === activeTab,
                })}
              >
                <Text className="text-center pb-6" color="var(--black)" variant="header-3">
                  {headline}
                </Text>
                {media && (
                  <div className={style.tabContent}>
                    <ImageOrVideo
                      srcSet={{
                        desktop: {
                          className: 'w-full h-full object-cover hidden md:block',
                          type: isVideo(media.type) ? media.type! : 'image',
                          thumbnail: videoThumbnail?.url || undefined,
                          ...media,
                        },
                        mobile: {
                          className: 'w-full h-full object-cover block md:hidden',
                          type: isVideo(media.type) ? media.type! : 'image',
                          thumbnail: videoThumbnail?.url || undefined,
                          ...mobileMedia,
                        },
                      }}
                    />
                  </div>
                )}
                <div className={cn(style.tabBody, 'w-5/6 text-center')}>
                  <Text variant="large" className="text-left" asElement="p">
                    {body}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Renderer({ name: 'moduleTabContent' })(ContentTabs);
