import cn from 'classnames';
import { FC } from 'react';

import { Video } from '@commerce/types/common';
import RichText from '@components/common/RichText/RichText';
import { ModulePillBoxItem, STYLE_VARIANT } from '@components/common/types/ModulePillBoxes';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Text from '@components/ui/Text/Text';

import s from './PillBoxItem.module.scss';

const PillBoxItem: FC<ModulePillBoxItem> = (props) => {
  const { buttonText, image, bottomText, header, styleVariant, index, video } = props;

  const isSlider = styleVariant === STYLE_VARIANT.SLIDER;

  const imageOrVideo = image || video;

  return (
    <section className={cn(s.pillContainer, { [s.pillContainerSlider]: isSlider })}>
      {!isSlider && (
        <div className={s.buttonContainer}>
          <div className={s.circle}>{index}</div>
          <Text variant="heading-2" className={s.buttonText} asElement="h2">
            {buttonText}
          </Text>
        </div>
      )}
      <div className={cn(s.imageContainer, { [s.imageContainerSlider]: isSlider })}>
        {imageOrVideo && (
          <ImageOrVideo
            className={s.image}
            type={image ? 'image' : (imageOrVideo as Video).type}
            {...imageOrVideo}
            loading="lazy"
          />
        )}
      </div>
      <div className={cn({ [s.textContainerSlider]: isSlider })}>
        {isSlider && (
          <Text variant="heading-2" className={s.header} asElement="h2">
            {header}
          </Text>
        )}
        <RichText className={cn(s.bottomText, { [s.bottomTextSlider]: isSlider })} document={bottomText} />
      </div>
    </section>
  );
};

export default PillBoxItem;
