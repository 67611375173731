import cn from 'classnames';
import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import Container from '@components/ui/Container';
import Text from '@components/ui/Text/Text';

import s from './Stats.module.scss';

interface Stat {
  content: {
    label: string;
    value: number;
  };
}

interface StatsProps {
  variant: string;
  header: string;
  stats: Stat[];
}

const StatElements: FC<{ stats: Stat[] }> = ({ stats }) => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-${stats.length} gap-4`}>
      {stats.map((stat: Stat) => (
        <div key={stat.content.label} className={s.stat}>
          <Text variant="header-2" className={cn('text-eggplant', s.statValue)}>
            {stat.content.value}
          </Text>
          <Text variant="header-5" className="text-eggplant">
            {stat.content.label}
          </Text>
        </div>
      ))}
    </div>
  );
};

const Stats: FC<StatsProps> = ({ header, stats, variant }) => {
  const theme = s[variant]; // eslint-disable-line

  return (
    <div className={cn(s.container, theme)}>
      <Container>
        <div className="py-12 md:p-6 md:pb-24">
          <Text variant="header-4" className="text-eggplant text-center mb-8">
            {header}
          </Text>
          <StatElements stats={stats} />
        </div>
      </Container>
    </div>
  );
};

export default Renderer({ name: 'moduleStats' })(Stats);
