import type { FC } from 'react';

import PlanItem from '../PlanItem/PlanItem';
import type { ModuleFlickableProduct } from '../types/ModuleFlickableProduct';

type Props = {
  product: ModuleFlickableProduct;
  isFeaturedProduct?: boolean;
};
const ProductTeaserCard: FC<Props> = ({ product, isFeaturedProduct = false }) => {
  if (!product.product) {
    return null;
  }
  const {
    description,
    headLine,
    useWhiteText,
    customUrl,
    primaryImage,
    flickImage,
    mobileFlickImage,
    mobilePrimaryImage,

    product: { content },
  } = product;
  const styles = isFeaturedProduct ? 'col-span-2 row-span-2' : '';

  return (
    <PlanItem
      className={styles}
      source={{
        ...primaryImage,
        type: 'image',
        loading: 'lazy',
      }}
      flickSource={{
        ...flickImage,
        type: 'image',
        loading: 'lazy',
      }}
      mobileSource={{
        ...mobilePrimaryImage,
        type: 'image',
        loading: 'lazy',
      }}
      flickMobileSource={{
        ...mobileFlickImage,
        type: 'image',
        loading: 'lazy',
      }}
      useWhiteText={useWhiteText}
      name={headLine}
      description={description || content?.name}
      url={customUrl || content?.url}
    />
  );
};

export default ProductTeaserCard;
