import { FC } from 'react';

import { Renderer } from '@components/screen/factory';
import { mapContent } from '@components/screen/helpers';
import Text from '@components/ui/Text/Text';
import { getContentfulImgSet, renderResponsiveImage } from '@lib/image';

import { ModuleUseCases, ModuleUseCasesItem, ModuleUseCasesUnmappedContent } from '../../common/types/ModuleUseCases';
import Button from '../Button/Button';
import Container from '../Container';
import Link from '../Link';

import style from './UseCases.module.scss';

type Props = ModuleUseCases;

// On mobile, text goes above the image
const UseCaseItem: FC<ModuleUseCasesItem> = ({ headline, description, image, lazyLoadImage = false, url }) => {
  const caseItem = (
    <div className="flex flex-col">
      {renderResponsiveImage(getContentfulImgSet(image, undefined, ['xs', 'sm']), {
        ...image,
        className: 'rounded-lg aspect-[311/180] md:aspect-[344/180] object-cover',
        loading: lazyLoadImage ? 'lazy' : 'eager',
      })}
      <Text variant="header-5-book" className={style.caseTitle}>
        {headline}
      </Text>
      {description && (
        <Text variant="base" className={style.caseDescription}>
          {description}
        </Text>
      )}
    </div>
  );
  return url ? (
    <Link href={url} aria-label={headline} prefetch={false}>
      {caseItem}
    </Link>
  ) : (
    caseItem
  );
};

const UseCases: FC<Props> = ({ headline, eyebrow, description, items, ctaText, ctaUrl, hasBackground }) => {
  return (
    <section className={!hasBackground ? 'bg-bone' : ''}>
      <Container mobilePadding className="pb-8 md:pb-16 lg:px-0 md:pt-14 pt-8">
        {eyebrow && (
          <Text variant="base-bold" className={style.eyebrow}>
            {eyebrow}
          </Text>
        )}
        {headline && (
          <Text variant="header-3" className={style.title}>
            {headline}
          </Text>
        )}
        {description && (
          <Text variant="header-5-book" className={style.subtitle}>
            {description}
          </Text>
        )}
        <div className="grid gap-y-10 md:gap-y-6 md:grid-cols-2 md:gap-x-6 lg:grid-cols-3">
          {items.map((item) => (
            <div key={item.internalName} className="useCaseItem">
              <UseCaseItem {...item} />
            </div>
          ))}
        </div>
        {ctaText && ctaUrl && (
          <div className="py-4 text-center">
            <Button href={ctaUrl} variant="cta">
              {ctaText}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

const mapper = ({ items, ...rest }: ModuleUseCasesUnmappedContent): Props => ({
  ...rest,
  items: mapContent(items),
});

export default Renderer({ name: 'moduleUseCases', mapper })(UseCases);
